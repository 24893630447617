<template>
	<div class="grid-container overflow-hidden" style="width: 100vw; height: 100vh" :key="cacheKey">
		<div class="navigation">
			<nav aria-label="breadcrumb" class="p-3">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link :to="{name: 'projects-index'}">Projects</router-link>
					</li>

					<li class="breadcrumb-item" v-if="project">
						<router-link :to="{
								name: 'projects-editor-uuid-index',
								params: {
									uuid: project.Uuid
								}
							}" v-text="`${project.Code} - ${project.Name}`"></router-link>
					</li>

					<li class="breadcrumb-item">
						<router-link :to="{
						name: 'projects-editor-uuid-cuuid-localizations-index',
						params: {
							uuid: this.$route.params.uuid,
							cuuid: this.$route.params.cuuid
						}
					}" v-if="configuration">{{ configuration.Name }} {{ `v. ${configuration.Version}` }}
						</router-link>
					</li>

					<li class="breadcrumb-item active" v-if="localization_project">
						<router-link :to="{
						name: 'projects-editor-uuid-cuuid-localizations-project_id-index',
						params: {
							uuid: this.$route.params.uuid,
							cuuid: this.$route.params.cuuid,
							project_id: this.$route.params.project_id
						}
					}">{{ localization_project.Name }}
						</router-link>
					</li>

					<li class="breadcrumb-item active">
						<router-link :to="{
						name: 'projects-editor-uuid-cuuid-localizations-project_id-pay_table-index',
						params: {
							uuid: this.$route.params.uuid,
							cuuid: this.$route.params.cuuid,
							project_id: this.$route.params.project_id
						}
					}">Pay table
						</router-link>
					</li>

					<li class="breadcrumb-item active" v-if="language">
						{{ language.Name }}
					</li>

				</ol>
			</nav>
		</div>
		<md-editor @save="save" v-if="payTable" class="editor" :extra-toolbars="extraToolbars" :value="payTable.Body"/>
<!--		<div id="app">-->
<!--			<ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>-->
<!--		</div>-->
	</div>
</template>

<script>
import api from '@/api'
import MdEditor from "@/components/localizations/MdEditor";
import messageBox from '@/components/modals/alert'
import CKEditor from '@ckeditor/ckeditor5-vue2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
	name: "pay_table-language",
	layout: 'empty',
	components: {MdEditor},
	data: () => ({
		loading: false,
		saving: false,
		project: null,
		configuration: null,
		localization_project: null,
		payTable: null,
		languages: [],
		editor: ClassicEditor,
		editorData: '<p>Content of the editor.</p>',
		editorConfig: {
			// The configuration of the editor.
		}
	}),
	async mounted() {
		this.loading = true
		this.languages = await api.Localizations.Languages.getList()
		this.project = await api.Projects.getProject(this.$route.params.uuid)
		this.configuration = await api.Projects.getProjectConfig(this.$route.params.cuuid)
		this.localization_project = await api.Localizations.LocalizationProjects.get(this.$route.params.project_id)
		await this.reloadLanguage(this.$route.params.language)
		this.loading = false
	},
	methods: {
		async save(text, html) {
			console.log(text)
			this.payTable = await api.Localizations.LocalizedPaytable.save({
				Language: this.$route.params.language,
				ProjectId: this.$route.params.project_id,
				Text: text
			})
			this.$toast.open(`${this.project.Name} / ${this.configuration.Name} / ${this.localization_project.Name} pay table saved!`)
		},
		async reloadLanguage(code) {
			this.payTable = await api.Localizations.LocalizedPaytable.getLocalized(code, this.$route.params.project_id)
			this.$toast.open(`Loaded`)
		}
	},
	computed: {
		cacheKey() {
			return `${this.$route.params.language}-${this.$route.params.project_id}`
		},
		language() {
			if (this.languages.length > 0) {
				return this.languages.filter(l => l.Code === this.$route.params.language)[0]
			}
			return null
		},
		paytableMD() {

			let standard_multipliers = ["5", "4", "3", "2"]

			let ret = `| ID | IMAGE | SYMBOL | `

			for (let m in standard_multipliers) {
				ret += ` ${standard_multipliers[m]}x | `
			}

			ret += '\n'

			ret += `|--|--|--|--|--|--|--|\n`

			if (this.localization_project) {
				for (let symbol_id in this.configuration.Configuration.paytable.multipliers) {
					const multipliers = this.configuration.Configuration.paytable.multipliers[symbol_id]

					let tmp = `| ${symbol_id} | ![Symbol ${symbol_id}](https://cdn-sp.nemesistst.com/clients/${this.localization_project.AssetsFolder}/symbols/${symbol_id}.png) | Symbol #${symbol_id} |`

					for (let m in standard_multipliers) {
						const mlt = multipliers[standard_multipliers[m]]
						tmp += `${mlt !== undefined && mlt.multiplier > 0 ? mlt.multiplier : '-'} | `
					}

					ret += `${tmp}\n`
				}
			}

			return ret
		},
		extraToolbars() {

			const vm = this

			let language_menus = this.languages.map(l => ({
				name: l.Name,
				text: l.Name,
				action() {
					vm.$router.push({
						name: 'projects-editor-uuid-cuuid-localizations-project_id-pay_table-language',
						params: {
							uuid: vm.$route.params.uuid,
							cuuid: vm.$route.params.cuuid,
							project_id: vm.$route.params.project_id,
							language: l.Code
						}
					})
					vm.reloadLanguage(l.Code)
				},
			}))

			return {
				paytable: {
					text: 'Paytable',
					title: 'Paytable',
					action(editor) {

						editor.insert((selected) => {
							return {
								text: vm.paytableMD,
								selected: vm.paytableMD,
							};
						});

						/*
						editor.insert((selected) => {

							const content = selected || this.paytableMD;

							return {
								text: this.paytableMD,
								selected: content,
							};
						})
						 */
					}
				},
				languages: {
					text: 'Switch language',
					title: 'Languages',
					menus: language_menus,
				},
			}
		},
	}
}
</script>

<style scoped>
.grid-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 50px 1fr;
	gap: 0px 0px;
	grid-template-areas:
    "navigation"
    "editor";
}

.navigation {
	grid-area: navigation;
}

.editor {
	grid-area: editor;
	height: auto;
	overflow: hidden;
}
</style>

<style lang="scss">

.v-md-editor-preview {

	$accent-color: #22f7c9;

	word-break: break-word;
	background-color: black;
	box-sizing: content-box;
	color: white;
	font-family: Verdana;


	/* width */
	::-webkit-scrollbar {
		width: 2px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: #858786;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: $accent-color;
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: darken($accent-color, 10%);
	}

	img {
		max-width: 500px;
		display: block;
		margin: 20px auto;
		border: 1px solid;
		background: #6d6d6d;
	}

	h1, h2, h3, h4 {
		padding-top: 5px;
	}

	h1, h2 {
		color: $accent-color;
		text-align: center;
		left: 2%;
		border-style: solid none solid none;
		border-color: $accent-color;
		border-width: thick;
	}

	h2 {
		border-width: thin;
	}

	p {
		left: 2%;
	}

	table {
		width: 100%;
		display: inline-table;
		border-spacing: 0;
	}

	table th, table td {
		text-align: center;
		border: 1px solid #80808038;
		padding: 5px;
		background-color: black;
	}
}

.v-md-editor__tooltip {
	font-family: Arial;
	background-color: #343434;
	border-radius: 0;
	padding: 8px 10px;
}

</style>
